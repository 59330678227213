<!--
 * @Author: your name
 * @Date: 2021-11-09 10:49:22
 * @LastEditTime: 2021-12-28 15:16:20
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\views\pages\general-user\safety-input\safety-plan\index.vue
-->
<template>
  <div>
    <el-form ref="queryFormRef" :inline="true" :model="queryForm" label-width="100px">
      <el-form-item label="所属项目部">
        <el-select v-model="queryForm.ids" placeholder="请选择">
          <el-option v-for="item in projectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSearch">查询</el-button>
        <el-button @click="handleReset">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="btns">
      <el-button type="primary" icon="el-icon-plus" v-if="$check('safety-use_add')" @click="handleAdd"
        >新增使用记录</el-button
      >
      <el-button
        @click="exportFunc"
        v-loading.fullscreen="exportLoading"
        element-loading-text="拼命导出中"
        element-loading-background="rgba(0, 0, 0, 0.1)"
        v-if="$check('safety-use_export')"
        :disabled="btnDisabeld"
        >导出</el-button
      >
    </div>
    <el-table v-loading="tableLoading" :data="planList" ref="multipleTable" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column
        fixed
        align="center"
        prop="projectDepartmentName"
        label="所属项目部"
        show-overflow-tooltip
        sortable
      ></el-table-column>
      <el-table-column
        align="center"
        width="300"
        prop="planTime"
        label="使用时间"
        show-overflow-tooltip
        sortable
      ></el-table-column>
      <el-table-column
        align="center"
        width="300"
        prop="planMoney"
        label="使用金额（元）"
        show-overflow-tooltip
        sortable
      ></el-table-column>
      <el-table-column fixed="right" align="center" width="200" label="操作">
        <template slot-scope="{ row }">
          <div>
            <span class="action-btn" @click="handleCetail(row)">查看详情</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="total"
      :current-page="queryForm.pageNo"
      :page-size="queryForm.pageSize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="[10, 20, 30, 50]"
      layout="total, sizes, prev, pager, next"
    ></el-pagination>

    <safatyDialog
      ref="safetyRef"
      :headerText.sync="headerText"
      @on-success="onSuccess"
      :projectList="projectList"
      dateName="请选择使用时间"
      category="使用金额（元）"
    />
  </div>
</template>
<script>
import safatyDialog from '../component/safetyDialog.vue';
import { getList, getProjectList, exportFundRecord } from '@/api/general-user/safety-input.js';
import { exportFun } from '@/utils/export.js';
export default {
  components: {
    safatyDialog
  },
  data() {
    return {
      total: 0,
      queryForm: {
        pageNo: 1,
        pageSize: 10,
        ids: [],
        type: 2
      },
      tableLoading: false,
      planList: [],
      projectList: [],
      multipleSelection: [],
      headerText: '新增使用计划',
      btnDisabeld: true,
      exportLoading: false
    };
  },
  created() {
    this.getList();
    this.getProjectList();
  },
  methods: {
    getProjectList() {
      getProjectList().then(res => {
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.projectList = res.data;
      });
    },
    handleSearch() {
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = 10;
      this.getList();
    },
    onSuccess() {
      this.handleSearch();
    },
    handleSizeChange(size) {
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = size;
      this.getList();
    },
    handleCurrentChange(current) {
      this.queryForm.pageNo = current;
      this.getList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = [];
      val.forEach(item => {
        this.multipleSelection.push(item.id);
      });
      this.btnDisabeld = !Boolean(this.multipleSelection.length);
    },
    handleAdd() {
      this.headerText = '新增使用计划';
      this.$refs.safetyRef.open(undefined, 2);
    },
    handleReset() {
      this.$refs.queryFormRef.resetFields();
      this.queryForm = this.$options.data().queryForm;
      this.handleSearch();
    },
    exportFunc() {
      this.exportLoading = true;

      exportFundRecord({
        ids: this.multipleSelection,
        type: 2
      })
        .then(res => {
          if (res.status === 200) {
            exportFun(res);
            this.exportLoading = false;

            this.$message({
              type: 'success',
              message: '导出数据成功'
            });
            this.$refs.multipleTable.clearSelection();
          }
        })
        .catch(() => {
          this.exportLoading = false;

          this.$refs.multipleTable.clearSelection();
        });
    },
    handleCetail(row) {
      this.headerText = '查看详情';
      this.$refs.safetyRef.open(row, 2);
    },
    getList() {
      this.tableLoading = true;
      getList(this.queryForm)
        .then(res => {
          this.total = res.data.total;
          this.planList = res.data.records;
        })
        .finally(() => {
          this.tableLoading = false;
        });
    }
  }
};
</script>
<style lang="less" scoped>
.btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  // align-items: center;
}
</style>
