<!--
 * @Author: your name
 * @Date: 2021-11-10 11:29:03
 * @LastEditTime: 2021-12-28 16:46:14
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\views\pages\general-user\safety-input\component\safetyDialog.vue
-->
<template>
  <el-dialog
    width="1030px"
    :title="headerText"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="handleColse"
  >
    <el-form ref="form" inline label-width="150px" :model="paramsForm" :rules="rules" v-loading="dialogLoading">
      <el-row v-if="!isDisabled">
        <el-col :span="24">
          <el-form-item label="请选择所属项目部" prop="projectDepartment">
            <el-select v-model="paramsForm.projectDepartment" placeholder="请选择" style="width:364px">
              <el-option v-for="item in projectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="dateName" prop="shijian">
            <el-date-picker
              @change="onchange"
              align="right"
              style="width:364px"
              v-model="paramsForm.shijian"
              type="month"
              value-format="yyyy-MM"
              :format="format"
              placeholder="请选择日期"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <div v-else class="titleDiv">
        <div class="titleName">四川省交通建设集团股份有限公司</div>
        <div class="content">{{ paramsForm.projectDepartmentName }}</div>
        <div class="content">安全经费计划使用台账（ {{ paramsForm.planTime }} ）</div>
      </div>
    </el-form>
    <el-table :data="planList" border show-summary :summary-method="getSummaries" v-loading="dialogLoading">
      <el-table-column align="center" width="80" type="index" label="序号"></el-table-column>
      <el-table-column
        align="center"
        width="300"
        prop="typeName"
        label="费用大类"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column align="center" width="300" prop="name" label="使用细目" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" width="150" prop="planMoney" :label="category" show-overflow-tooltip>
        <template slot-scope="{ row }">
          <div>
            <el-input
              v-model="row.planMoney"
              :disabled="isDisabled"
              placeholder="请输入"
              @input="onChangeInput(row)"
            ></el-input>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="remark" label="备注" show-overflow-tooltip show-overflow-tooltip>
        <template slot-scope="{ row }">
          <div>
            <el-tooltip
              class="item"
              :disabled="row.remark == '' || row.remark == undefined"
              effect="dark"
              :content="row.remark"
              placement="top-start"
            >
              <el-input v-model="row.remark" :disabled="isDisabled" placeholder="请输入"></el-input>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer">
      <el-button
        @click="deleteFunc"
        type="danger"
        v-if="
          (isDisabled && typeData === 1 && $check('safety-plan_delete')) ||
            (isDisabled && typeData === 2 && $check('safety-use_delete'))
        "
        >删 除</el-button
      >
      <el-button
        @click="openDetail"
        type="primary"
        v-if="
          (isDisabled && typeData === 1 && $check('safety-plan_edit')) ||
            (isDisabled && typeData === 2 && $check('safety-use_edit'))
        "
        >修 改</el-button
      >
      <el-button type="primary" v-if="!isDisabled" :loading="confirmLoading" @click="handleConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { cloneDeep } from 'lodash';
import { getItem } from '@/utils/storage.js';
import {
  postAdd,
  postEdit,
  postSortList,
  getFundDetail,
  deletePlan,
  checkPlanApi
} from '@/api/general-user/safety-input.js';

export default {
  props: {
    headerText: {
      type: String
    },
    category: {
      type: String
    },
    dateName: {
      type: String
    },
    projectList: {
      type: Array
    }
  },
  data() {
    return {
      visible: false,
      format: 'yyyy-MM',
      paramsForm: {
        projectDepartment: '',
        year: '',
        month: '',
        shijian: '',
        type: null,
        fundingDetailParams: []
      },

      rules: {
        projectDepartment: [{ required: true, message: '请选择所属项目部', trigger: 'change' }],
        shijian: [{ required: true, message: '请选择日期', trigger: 'change' }]
      },
      dialogLoading: false,
      projectListc: [],
      planList: [],
      typeData: null,
      pickerOptions: {
        shortcuts: [
          {
            text: '全年',
            onClick(picker) {
              picker.$emit('pick', picker.year + '');
            }
          }
        ]
      },

      isDisabled: false,
      confirmLoading: false,
      planId: ''
    };
  },
  methods: {
    open(row, type) {
      this.typeData = type;
      this.visible = true;
      if (row) {
        this.planId = row.id;
        getFundDetail({ planId: this.planId }).then(res => {
          res.data.fundingDetailPageResultList.forEach(item => {
            item.name = item.detailName;
          });
          this.planList = res.data.fundingDetailPageResultList;
        });
        this.isDisabled = true;
        this.paramsForm = row;
        this.paramsForm.type = type;

        if (row.planTime.length === 6) {
          this.format = 'yyyy';
          this.$set(this.paramsForm, 'shijian', row.planTime.replace('年度', ''));
          //  = row.planTime.replace('年度', '');
        } else {
          this.format = 'yyyy-MM';
          this.$set(this.paramsForm, 'shijian', row.planTime.replace('年', '-'));
          // this.paramsForm.shijian = row.planTime;
        }
      } else {
        this.isDisabled = false;
        this.paramsForm.type = type;
        this.postSortList();
      }
    },
    postSortList() {
      postSortList({ tenantId: getItem('tenantInfo').tenantId }).then(res => {
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        res.data.forEach(item => {
          item.planMoney = 0;
        });
        this.planList = res.data;
      });
    },
    handleColse() {
      this.visible = false;
      this.$refs.form.resetFields();
      this.paramsForm = this.$options.data().paramsForm;
      this.planList = [];
    },
    onchange(data) {
      if (data.length === 4) {
        this.format = 'yyyy';
        this.paramsForm.year = data;
        this.paramsForm.month = '';
      } else {
        this.format = 'yyyy-MM';
        this.paramsForm.year = data.slice(0, 4);
        this.paramsForm.month = data.slice(5, 7);
      }
    },
    deleteFunc() {
      this.$confirm('请确认是否删除该计划', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.deletePlan();
      });
    },
    //删除接口
    deletePlan() {
      deletePlan({ planId: this.planId }).then(res => {
        if (res.code === 200) {
          this.$message.success('删除成功');
          this.handleColse();
          this.$emit('on-success');
        }
      });
    },
    openDetail() {
      this.dialogLoading = true;
      this.isDisabled = false;
      this.$emit('update:headerText', '修改计划');
      setTimeout(() => {
        this.dialogLoading = false;
      }, 500);
    },
    handleConfirm() {
      this.$refs.form.validate(valid => {
        if (!valid) return;
        this.checkPlan();
      });
    },
    //检查是否已经存在计划或者使用（返回不为空 则已经存在）
    checkPlan() {
      checkPlanApi(this.paramsForm).then(res => {
        if (res.code === 200) {
          if (res.data) {
            //代表已经存在
            this.deleteConfrimFunc();
          } else {
            //代表不存在，直接调取新增接口
            this.addPlan();
          }
        }
      });
    },
    //新增计划
    addPlan() {
      this.paramsForm.fundingDetailParams = cloneDeep(this.planList);
      this.paramsForm.fundingDetailParams.forEach(item => {
        item.detailId = item.id;
        item.detailName = item.name;
      });
      this.$refs.form.validate(valid => {
        if (!valid) return;
        this.confirmLoading = true;
        let flag = false;
        flag = this.paramsForm.fundingDetailParams.every(item => item.planMoney !== '');
        if (!this.paramsForm.id) {
          if (flag) {
            this.paramsForm.fundingDetailParams.forEach(item => {
              delete item.id;
            });
            postAdd(this.paramsForm)
              .then(res => {
                this.$message.success('新增成功！');
                this.handleColse();
                this.$emit('on-success');
              })
              .finally(() => {
                this.confirmLoading = false;
              });
          } else {
            this.$message.error('请填写金额');
            this.confirmLoading = false;
          }
        } else {
          this.paramsForm.planId = this.paramsForm.id;
          if (this.paramsForm.shijian.length === 6) {
            this.paramsForm.year = this.paramsForm.shijian.slice(0, 4);
          } else {
            this.paramsForm.year = this.paramsForm.shijian.slice(0, 4);
            this.paramsForm.month = this.paramsForm.shijian.slice(5, 7);
          }
          this.paramsForm.type = this.typeData;

          if (flag) {
            postEdit(this.paramsForm)
              .then(res => {
                this.$message.success('编辑成功！');
                this.handleColse();
                this.$emit('on-success');
              })
              .finally(() => {
                this.confirmLoading = false;
              });
          } else {
            this.$message.error('请填写金额');
            this.confirmLoading = false;
          }
        }
      });
    },
    deleteConfrimFunc() {
      this.$confirm('该项目部在本时间已存在使用记录/计划，请确认是否覆盖该记录/计划?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.addPlan();
      });
    },
    onChangeInput(row, index) {
      const numberReg = /^[0-9]{0,10}?.?\d{0,2}$/;
      if (row.planMoney < 0) {
        row.planMoney = 0;
      } else if (/[^\d^\.]+/g.test(row.planMoney)) {
        row.planMoney = 0;
        this.$message.error('只能输入数字');
      } else if (!numberReg.test(row.planMoney)) {
        this.$message.error('只能输入小数点前10位，后两位');
        row.planMoney = 0;
      }
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (column.property === 'planMoney') {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return (prev * 100 + curr * 100) / 100;
            } else {
              return prev;
            }
          }, 0);
          sums[index];
        } else {
          sums[index] = '-';
        }
      });
      return sums;
    }
  },
  watch: {
    projectList: {
      handler: function(val) {
        this.projectListc = val;
      },
      deep: true
    }
  }
};
</script>
<style lang="less" scoped>
.titleDiv {
  width: 100%;
  div {
    text-align: center;
  }
  .titleName {
    font-size: 16px;
    font-weight: 700;
    color: #303133;
    margin-bottom: 5px;
  }
  .content {
    font-size: 14px;
    color: #616266;
    margin: 10px 0;
  }
}
/deep/ .el-picker-panel__sidebar {
  top: 226px !important;
  right: 39px !important;
  bottom: 0 !important;
  width: 0 !important;
}
</style>
<style>
.el-picker-panel__sidebar {
  top: 240px !important;
  right: 21px !important;
  bottom: 0 !important;
  width: 46px !important;
  overflow: hidden !important;
  border-right: none !important;
  z-index: 10000;
}
.el-date-picker .el-picker-panel__content {
  height: 220px !important;
  width: 100% !important;
}
.el-picker-panel [slot='sidebar'] + .el-picker-panel__body,
.el-picker-panel__sidebar + .el-picker-panel__body {
  margin-left: 0;
}
</style>
