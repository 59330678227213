import { render, staticRenderFns } from "./safetyDialog.vue?vue&type=template&id=cd454fa0&scoped=true&"
import script from "./safetyDialog.vue?vue&type=script&lang=js&"
export * from "./safetyDialog.vue?vue&type=script&lang=js&"
import style0 from "./safetyDialog.vue?vue&type=style&index=0&id=cd454fa0&lang=less&scoped=true&"
import style1 from "./safetyDialog.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd454fa0",
  null
  
)

export default component.exports